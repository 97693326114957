import { AgwConstants } from '@app/interfaces/AgwConstants';
import { LegType, MessageType } from '@app/interfaces/generated/pim.types';
import {
	EGCurrency,
	EGLanguageBCP47
} from '@app/interfaces/uimodels/global/types';
import moment from 'moment';

export class FrontendConstants {
	/**
	 * @deprecated
	 */
	public static isoDateFormat = AgwConstants.ISO_DATE;
	public static defaultDateOutputFormat = 'dd.MM.YYYY';
	public static defaultDateMomentFormat = 'DD.MM.YYYY';
	public static defaultTimeOutputFormat = AgwConstants.ISO_TIME_SHORT;
	public static yearOnlyMomentFormat = 'YYYY';
	public static monthOnlyMomentFormat = 'MM';
	public static alternativeDateTimeOutputFormat = 'DD.MM.YYYY - HH:mm';
	public static dashLessDateTimeOutputFormat = 'DD.MM.YYYY HH:mm';

	public static isoString = 'YYYY-MM-DDTHH:mm:00';

	public static allowedOfferDocumentFileExtensions = ['.pdf', '.ods', '.xlsx', '.csv', '.xls'];

	public static searchRelations = {
		minimalStartDate: moment()
			.subtract(1, 'd')
			.format(FrontendConstants.isoDateFormat),
		paramsDateFormat: AgwConstants.ISO_DATE_TIME_SHORT,
		startDateDefaultFormat: 'DD.',
		startDateMonthRangeFormat: 'DD.MM.',
		fullDateOutputFormat: 'DD.MM.YYYY',
	};

	public static offer = { //
		searchAddressCombo: { //
			allowedDistanceFromOriginalSearchAddressKm: 40,
			allowedDistanceFromFreeLegAddressToTerminalKm: 100
		}
	};

	public static get defaultDateTimeOutputFormat(): string {
		return `${this.defaultDateOutputFormat} ${this.defaultTimeOutputFormat}`;
	}

	public static get defaultDateTimeMomentFormat(): string {
		return `${this.defaultDateMomentFormat} ${this.defaultTimeOutputFormat}`;
	}

	public static allowedLegsOnewayContinental = [LegType.DEPOT_PICKUP, LegType.FIRST, LegType.MAIN, LegType.LAST, LegType.DEPOT_DROPOFF];

	public static allowedLegsOnewayImport = [LegType.MAIN, LegType.LAST, LegType.DEPOT_DROPOFF];

	public static allowedLegsOnewayExport = [LegType.DEPOT_PICKUP, LegType.FIRST, LegType.MAIN];

	public static allowedLegsRoundTripImport = [LegType.MAIN, LegType.LAST, LegType.MAIN_EMPTY, LegType.DEPOT_DROPOFF];

	public static allowedLegsRoundTripExport = [LegType.DEPOT_PICKUP, LegType.MAIN_EMPTY, LegType.FIRST, LegType.MAIN];

	public static offerDetails = { //
		loadingData: { //
			maxFilesizeUpload: 5242880,
			maxFullFilesizePerUpload: 15728640
		},
		historyData: {
			dateTimeMomentFormat: this.defaultDateTimeOutputFormat,
			dayMomentFormat: 'DD.MM.YYYY'
		}
	};
	public static basisData = { //
		generalTerms: { //
			maxFilesizeUpload: 5242880
		},
		scheduleList: { //
			maxFilesizeUpload: 5242880
		}
	};
	public static profile = { //
		company: { //
			logo: { //
				maxFilesizeUpload: 5242880
			}
		},
		contact: { //
			profilePicture: { //
				maxFilesizeUpload: 5242880,
			},
		}
	};
	public static registration = { //
		company: { //
			logo: { //
				maxFilesizeUpload: 5242880
			}
		}
	};

	public static preferredLocaleCookieField = 'preferredLocale';
	public static defaultBrowserLanguage = 'de';
	public static defaultLocale = 'de-de';
	public static priceLists = { defaultPageSize: 100 };
	public static defaultCurrencyCode = EGCurrency.EUR;
	public static displayedMessageTypes = {
		tasks: [MessageType.ACTIVITY],
		messages: [MessageType.FEEDBACK_REQUEST, MessageType.INFO],
		all: [MessageType.ACTIVITY, MessageType.FEEDBACK_REQUEST, MessageType.INFO]
	};

	public static decimalFormatter(locale?: EGLanguageBCP47): Intl.NumberFormat {
		const formatter = new Intl.NumberFormat(locale ?? EGLanguageBCP47.DE);

		return formatter;
	}
}
