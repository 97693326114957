// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GraphCmsStage } from '@app/interfaces/dtos/GraphCmsStage';
import { StringIndexedObject } from '@app/interfaces/utils/StringIndexed';

export enum EGEnvironmentName {
	DEV = 'DEV',
	TST = 'TST',
	TLB = 'TLB',
	PRD = 'PRD',
	LOC = 'LOC'
}

export interface IGEnvironment extends StringIndexedObject {
	envName?: EGEnvironmentName;
	production: boolean;
	debug: boolean;
	transformer: boolean;

	rootUrl: string;
	enableLogging: boolean;
	sourceMapsAvailable: boolean;
	disableBookingButton: boolean;
	useGraphCms: boolean,
	graphCmsStage: GraphCmsStage,
	graphCmsCacheEnabled: boolean,
	graphCmsCacheTtl: number,
	hotJarEnabled: false,
	pendoEnabled: boolean,
	pendoApiKey: string,
	hereMapsApiKey: string,
	googleTagManagerEnabled: boolean,
	googleTagManagerCode: string,
	graphCmsAuthToken: string,
	googleMapsApiKey: string,
	freshWorksBaseUrl: string,
	freshWorksWidgetKey: string,
	userflowToken: string,
	mailChimpApiKey: string,
	mailChimpApiKeyVersion: string,
	mailChimpAudienceId: string,
	maxDistanceFromTerminal: number,
	enableJiraErrorTicketCreation: boolean,
	jiraUser: string,
	jiraPassword: string,
	messageQueue: {
		host: string,
		queueName: string,
		username: string,
		password: string
	}
}

export const EnvironmentConfigKeyName = 'environment';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
