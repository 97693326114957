import { AgwConstants } from '@app/interfaces/AgwConstants';
import { FrontendConstants } from '@app/interfaces/constants/frontend.constants';
import {
	DateAsString,
	DurationData
} from '@app/interfaces/generated/pim.types';
import moment, { Moment } from 'moment-timezone';

export class DateTimeStampUtils {

	public static getCurrentTimezone(long = true): string {
		if (long) {
			return Intl.DateTimeFormat()
				.resolvedOptions().timeZone;
		} else {
			return new Date()
				.toLocaleTimeString('en-us', { timeZoneName: 'short' })
				.split(' ')?.[2] ?? 'UTC';
		}
	}

	public static getIsoTimeOnly(dateTimeStamp = ''): string {
		const returnValue = moment(dateTimeStamp)
			.format(AgwConstants.ISO_TIME_SHORT);

		return returnValue;
	}

	public static getIsoDateOnly(dateTimeStamp?: string): string | undefined {
		const returnValue = dateTimeStamp
			? moment(dateTimeStamp)
				.format(AgwConstants.ISO_DATE)
			: undefined;

		return returnValue;
	}

	public static convertLocalDateToTimezonedDate(date?: DateAsString): DateAsString | undefined {
		if (date && !date.endsWith('Z')) {
			return date + 'Z';
		} else {
			return date;
		}
	}

	public static isDateTimeStamp(str = ''): boolean {
		const returnValue = String(str)
			?.indexOf('T') > -1;

		return returnValue;
	}

	public static getLocalTime(date: string): string {
		const timezone = Intl.DateTimeFormat()
			.resolvedOptions().timeZone;

		const returnValue = moment.utc(date)
			.tz(timezone)
			.format(FrontendConstants.defaultDateMomentFormat) || '';

		return returnValue;
	}

	public static toDateTimeStamp(date?: string, time?: string): string | undefined {
		let checkedDate: string | undefined = date;
		let checkedTime = time;

		if (DateTimeStampUtils.isDateTimeStamp(date)) {
			checkedDate = DateTimeStampUtils.getIsoDateOnly(date);
		}

		if (DateTimeStampUtils.isDateTimeStamp(time)) {
			checkedTime = DateTimeStampUtils.getIsoTimeOnly(time);
		}

		const returnValue = checkedDate
			? moment([checkedDate, checkedTime].join('T'))
				.format('YYYY-MM-DDTHH:mm:ss')
			: undefined;

		return returnValue;
	}

	public static minsToMilliseconds(mins: number): number {
		const returnValue = mins * 60 * 1000;

		return returnValue;
	}

	public static isValidMomentDate(date?: Moment): boolean {

		let returnValue = true;
		let dateStr;

		try {
			dateStr = date?.format?.(AgwConstants.ISO_DATE);
		} catch (err) { //
		}

		if (!dateStr || dateStr === AgwConstants.INVALID_DATE) {
			returnValue = false;
		}

		return returnValue;
	}

	public static handleDateKeyboardInputValue(event: InputEvent): string {

		const placeholder = '__.__.__';

		const length: number = placeholder.length;
		const value: string = (event.target as HTMLInputElement)?.value;
		let newValue = '';

		// strip special characters
		const strippedValue = value.replace(/\D/g, '');

		for (let i = 0, j = 0; i < length; i++) {
			const isInt = !isNaN(parseInt(strippedValue[j]));
			const matchesNumber = placeholder[i] === '_';

			if (matchesNumber && isInt) {
				newValue += strippedValue[j++];
			} else if ((!isInt && matchesNumber) || (matchesNumber && !isInt)) {
				return newValue;
			} else {
				newValue += placeholder[i];
			}

			// break if no characters left and the pattern is non-special character
			if (strippedValue[j] == undefined) {
				break;
			}
		}

		return DateTimeStampUtils.validateDateKeyboardInput(newValue);
	}

	public static validateDateKeyboardInput(value: string): string {

		// const pattern = new RegExp('(1[0-9]|0[1-9]|2[0-9]|3[0-1])[.]{1}(1[0-2]|0[1-9])[.]{1}(1[0-9]|2[0-9])');
		const pattern = new RegExp('(1[0-9]|0[1-9]|2[0-9]|3[0-1])[.]{1}(1[0-2]|0[1-9])[.]{1}([0-9][0-9])');
		const validExample = '21.01.22';
		const longMonth: Array<string> = ['01', '03', '05', '07', '08', '10', '12'];

		let length = value.length;
		let testValue = '';

		// convert to month
		if (length === 4) {
			if (value.substring(length - 1, length)?.length > 1) {
				value = value.substr(0, length - 1) + '0' + value.substr(length - 1, length);
				length = value.length;
			}
		}

		// test the value, removing the last character, until what you have is a submatch
		for (let i = length; i >= 0; i--) {
			testValue = value + validExample.substr(value.length);

			if (pattern.test(testValue)) {
				if (length === 5 && value.substr(0, 2) === '31' && longMonth.indexOf(value.substr(3)) < 0) {
					value = value.substr(0, value.length - 1);
				}

				return value;
			} else {
				value = value.substr(0, value.length - 1);
			}
		}

		return value;
	}

	public static addDuration(dateTime?: string, duration?: DurationData): string {
		const returnValue = moment(dateTime)
			.add(duration?.days, 'd')
			.add(duration?.hours, 'h')
			.add(duration?.minutes, 'm')
			.format(FrontendConstants.isoString);

		return returnValue;
	}

	public static subtractDuration(dateTime?: string, duration?: DurationData): string {
		const returnValue = moment(dateTime)
			.subtract(duration?.days, 'd')
			.subtract(duration?.hours, 'h')
			.subtract(duration?.minutes, 'm')
			.format(FrontendConstants.isoString);

		return returnValue;
	}
}
