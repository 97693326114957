import { IGEnvironment } from '@app/interfaces/server/environment.interface';

//the object keys of environment are loaded with proxymap and assigned by ProxyBuilder
export const environment: Partial<IGEnvironment> = {};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
