export class CookiesUtils {
	public static getCookie(name: string): string {
		const cookies: Array<string> = document.cookie.split(';');
		const cookiesLength: number = cookies.length;
		const cookieName = `${name}=`;
		let cookie: string;

		for (let i = 0; i < cookiesLength; i += 1) {
			cookie = cookies[i].replace(/^\s+/g, '');

			if (cookie.indexOf(cookieName) == 0) {
				return cookie.substring(cookieName.length, cookie.length);
			}
		}

		return '';
	}

	public static deleteCookie(name: string): void {
		CookiesUtils.setCookie(name, '', -1);
	}

	public static setCookie(name: string, value: string, expireDays = 1000, path = '/'): void {
		const date: Date = new Date();
		date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
		const expires = `expires=${date.toUTCString()}`;
		const cookiePath: string = path ? `; path=${path}` : '';
		document.cookie = `${name}=${value}; ${expires}${cookiePath}; SameSite=Strict`;
	}
}
