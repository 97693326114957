export class EnumUtil {
	public static getMemberForValue<T>(enumType: Record<string, unknown>, value: unknown): T | undefined {
		const foundProperty = Object.keys(enumType ?? {}).find((prop) => enumType[prop] === value);

		return foundProperty as unknown as T;
	}

	public static getMemberValue<T>(enumType: Record<string, unknown>, member?: string): T | undefined {
		if (member) {
			const foundProperty = Object
				.keys(enumType ?? {})
				.find((prop) => prop === member);

			return foundProperty
				? enumType[foundProperty] as unknown as T
				: undefined;
		}

		return undefined;
	}

}
